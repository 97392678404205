import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react'
import { useToaster } from '../../hooks'
import { Barber, CancelToken, Query, Service } from '../../types'
import {
  executeGraphQL,
  // getCancelTokenSource,
  isCancelError,
  now,
} from '../../util'

type Props = { children?: ReactNode }

type ProviderContext = {
  services: Service[]
  barbers: Barber[]
  loadData: (cancelToken?: CancelToken) => Promise<void>
  setServices: React.Dispatch<React.SetStateAction<Service[]>>
  setBarbers: React.Dispatch<React.SetStateAction<Barber[]>>
}

export const DataProviderContext = createContext<ProviderContext | null>(null)

const DataProvider: FC<Props> = ({ children }) => {
  const [services, setServices] = useState<Service[]>(
    [
      {
        description: 'Traditional haircuts, fade cuts, buzz cuts, etc',
        name: 'Haircut',
        price: 27,
      },
      {
        description:
          'Precise hairline trim, also known as a line up or shape up',
        name: 'Edge Up',
        price: 15,
      },
      {
        description: 'Precise eyebrow trim',
        name: 'Eyebrows',
        price: 8,
      },
      {
        description: 'Custom haircut designs, prices may very by complexity',
        name: 'Designs',
        price: 15,
      },
      {
        description: 'Includes haircut, edge up, eyebrows, etc',
        name: 'The Works',
        price: 45,
      },
      {
        name: 'Beard',
        description: 'Traditional facial hair trim or edge up',
        price: 12,
      },
      {
        name: 'Facial Massage',
        description: 'Soothing traditional facial massage',
        price: 30,
      },
      {
        name: 'Scalp Treatment',
        description: 'Soothing scalp treatment using special conditioner',
        price: 30,
      },
      {
        name: 'Straight Razor',
        description: 'Traditional beard edge up using a classic straight razor',
        price: 10,
      },
    ].map((s, i) => ({
      ...s,
      _id: i.toString(),
      created_at: now(),
      updated_at: now(),
    }))
  )
  const [barbers, setBarbers] = useState<Barber[]>([])

  const { popToast } = useToaster()

  const loadData = useCallback<ProviderContext['loadData']>(
    async (cancelToken) => {
      const errorMessage =
        'Unable to load needed site data. Please try again later.'

      try {
        const { data, errors } = await executeGraphQL<Query>({
          gql,
          cancelToken,
        })

        const services = data?.service.getServices ?? []
        const barbers = data?.barber.getBarbers ?? []

        if (errors) {
          popToast({ message: errorMessage, type: 'error' })
        }

        setServices(services)
        setBarbers(barbers)
      } catch (err) {
        if (!isCancelError(err)) {
          popToast({ message: errorMessage, type: 'error' })
        }
      }
    },
    [popToast]
  )

  useEffect(() => {
    // const source = getCancelTokenSource()
    // loadData(source.token)
    // return () => {
    //   source.cancel()
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <DataProviderContext.Provider
      value={{ services, barbers, loadData, setBarbers, setServices }}
    >
      {children}
    </DataProviderContext.Provider>
  )
}

const gql = `
  query {
    service {
      getServices {
        _id
        name
        description
        price
        created_at
        updated_at
      }
    }
    barber {
      getBarbers {
        _id
        name
        email
        phone_number
        availability {
          start
          end
        }
        created_at
        updated_at
      }
    }
  }
`

export default DataProvider
